const backCard = (card, front, back) =>{
    const chiefCard = document.querySelectorAll(card);
            // frontCard = document.querySelector(front),
            // backCard = document.querySelector(back);
  
    chiefCard.forEach(cards=>{
        cards.addEventListener('click', function(e){
            const target = e.target;
            // this.querySelector(front).classList.add('front_hide');
            // this.querySelector(back).classList.remove('back');
            // if(target){
            //     console.log('перед');
            //     this.querySelector(front).classList.add('front_hide');
            //     this.querySelector(back).classList.remove('back');
            // } 
           if (target&&this.querySelector(front).classList.contains('front_hide')){
                console.log('должно перевернуться');
                this.querySelector(front).classList.remove('front_hide');
                this.querySelector(back).classList.add('back');
            } else {
                console.log('перед');
                this.querySelector(front).classList.add('front_hide');
                this.querySelector(back).classList.remove('back');
            }
        //     if(target) {
        //         console.log('перед');
        //         this.querySelector(front).classList.add('front_hide');
        //         this.querySelector(back).classList.remove('back');
        //    }
            
           
           
            
            // else {
            //     this.querySelector(front).classList.add('front_hide');
            //     this.querySelector(back).classList.remove('back');
            // }
            // else {
            //     console.log('задняя часть');
            //     target.querySelector(front).classList.remove('front_hide');
            //     target.querySelector(back).classList.add('back');
            //     // target.querySelector(back).classList.add('active-desc_back');
            // }
        });
    });
    
};
export default backCard;