
import description from './modules/description';
import backCard from './modules/backcard';

'use strict';
window.addEventListener('DOMContentLoaded', ()=>{

    description('.course-block__item','.course-block__description');
    backCard('.course-block__item', '.course-block__front', '.description');

});