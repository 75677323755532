// При наведени мыши на изображение всплывает описание содержимого

const description = (fon, elem) =>{
    const fons = document.querySelectorAll(fon),
        elements = document.querySelectorAll(elem);
        
    
    function show (obj) {
        obj.style.display = 'block';
    }
    function hide (obj) {
        obj.style.display = 'none';
    }

    function showFon (obj) {
        obj.style["boxShadow"]= 'none';
        obj.style["boxShadow"]= '0px 4px 8px 17px rgba(0, 52, 251, 0.2)';
    }
    function hideFon (obj) {
        obj.style["boxShadow"]= 'none';
        obj.style["boxShadow"] = '0px 4px 8px 9px rgba(4, 38, 65, 0.2)';
    }

    fons.forEach(item=>{
        item.addEventListener('mouseover', ()=>{
            showFon(item);
        });
        item.addEventListener('mouseout', ()=>{
            hideFon(item);
        });
    });
    elements.forEach(item=>{
        item.parentNode.addEventListener('mouseover', ()=>{
            show(item);
        });
        item.parentNode.addEventListener('mouseout', ()=>{
            hide(item);
        });
    });
    
};
export default description;